<template>
  <div class="container">
    <h2>Programs</h2>
    <h3>List of avaliable programs</h3>
    <table class="table">
      <thead></thead>
      <tbody>
        <tr>
          <td>Location Name</td>
          <td>Admin</td>
          <td>Delegates</td>
          <td># of Users</td>
        </tr>
        <tr v-for="(gym, key) in locations" :key="key">
          <td>{{ key }}</td>
          <td>{{ gym.admin }}</td>
          <td>{{ gym.delegate }}</td>
          <td>{{ gym.totalUsers }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},

  props: {
    nombre: String,
  },

  data() {
    return {
      info: "",
      name: "",
    };
  },

  computed: {
    ...mapGetters("user", ["getUserIsLogged"]),
  },
};
</script>

<style lang="scss" scoped></style>
